import Login from "../pages/auth/Login"
import Home from "../pages/public/Home"
import PaymentTesting from "../pages/public/PaymentTesting/PaymentTesting"
import Victims from "../pages/public/Victims"



export const authenticatedRoutes = [
    {type:'menu',label:'InterView Test',path:'/dashboard',element:<Home/>,icon:'ri-home-8-line',children:[]},
    // {type:'menu',label:'Victims',path:'/victims',element:<Victims/>,icon:'ri-home-8-line',children:[]},
    {type:'menu',label:'Payment Testing',path:'/pg/phonepe',element:<PaymentTesting/>,icon:'ri-home-8-line',children:[]},
    {path:'/pg/phonepe/redirect',element:<PaymentTesting/>,icon:'ri-home-8-line',children:[]},
]
export const publicRoutes = [
    
]
export const authRoutes = [
    {label:'Login',path:'/',element:<Login /> ,icon:'null',users:[],children:[]},
    {label:'Login',path:'/login',element:<Login /> ,icon:'null',users:[],children:[]},
]