import React, { useMemo } from 'react'
import { usePageInitialtor } from '../../helper/custom_hook'
import { userMedia } from '../../helper/api_url'
import BreadCrumb from '../../components/common/BreadCrumb';
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import {TableResponsive} from "../../components/common/TableResponsive";
import ImageViewer from '../../components/ImageViewer';
import { swal } from '../../helper/swal';

function Victims() {
    const {tableData,setTableData,tableLoading} = usePageInitialtor(userMedia);
    const columns = useMemo(()=>[
        {
            Header: "Image",
            Cell:(cell)=>{
                const imageUrl = "https://candidinfo.skicst.org/system/" + cell.row.original.img;
                return (
                    <ImageViewer image={imageUrl} >
                         <img className="rounded" style={{ aspectRatio:'1/1',width:'100px', objectFit:'cover'}} src={imageUrl} alt="User Avatar" />
                    </ImageViewer>
                )
            }
        },
        {
            Header: "Latitude",
            accessor: "lat",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header: "Longitude",
            accessor: "lon",
            HeaderClass:'text-center',
            DataClass:'text-center',
        },
        {
            Header:'List',
            HeaderClass:'d-none',
            DataClass:'d-none',
            list:(row)=>{
                const imageUrl = "https://candidinfo.skicst.org/system/" + row.img;
                return (
                <div className="d-flex">
                    <ImageViewer image={imageUrl} >
                         <img className="rounded" style={{ aspectRatio:'1/1',width:'100px', objectFit:'cover'}} src={imageUrl} alt="User Avatar" />
                    </ImageViewer>
                    <div className="flex-grow-1 ms-3" data-id="1">
                        <h3>Location Details</h3>
                        <h5 className="fs-13">
                            <p className='text-muted'> Latitude : {row.lat}</p>
                            <p className='text-muted'> Longitude : {row.lon}</p>
                        </h5>
                    </div>
                </div>
                )
            }
          }
    ]);
    const handleAllDelete = () => userMedia.deleteAll()
    .then(r=>setTableData(r.data.victims))
    .catch(e=>swal.error(e.response?.data?.message))
    return (
        <>
            <BreadCrumb title="victim list" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Victims</h5>
                            <button onClick={handleAllDelete} className='btn btn-danger' >Delete all</button>
                        </CardHeader>
                        <CardBody className="">
                            <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record in This List"  />
                        </CardBody> 
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Victims