import React from 'react'
import BreadCrumb from '../../../components/common/BreadCrumb';
import { Card, CardBody, CardHeader, Col, Row } from 'react-bootstrap';
import { TableResponsive } from '../../../components/common/TableResponsive';
import { useDispatch } from 'react-redux';
import { setPreloader } from '../../../features/Ui/uiSlice';
import { pg } from '../../../helper/api_url';

function PaymentTesting() {
    const dispatch = useDispatch();
    const handlePayment = () => {   
        const formData = new FormData();
        formData.append('amount',10000);
        dispatch(setPreloader({loader:true,message:'Initiationg payment Please Wait ....'}));
        pg.phonepeINIT(formData)
        .then(r=>window.location = r.data.redirect.url)
        .catch(e=>console.error(e))
        .finally(()=>dispatch(setPreloader({loader:false,message:''})));
    }
    return (
        <>
            <BreadCrumb title="Payment Gateway Test" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center justify-content-between">
                            <h5 className="card-title mb-0">Payment</h5>
                            <button onClick={handlePayment} className='btn btn-success' >Pay</button>
                        </CardHeader>
                        <CardBody className="">
                            {/* <TableResponsive isLoading={tableLoading} columns={columns} data={tableData} noRecord="No Record in This List"  /> */}
                        </CardBody> 
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default PaymentTesting